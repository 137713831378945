$(function() {
  $(document).on('change', '.selectable-select', function() {
    var id = $(this).val();
    $.getJSON("/products/get_product_reference/" + id + ".json", (function(result) {
      $('.text-refer').text(result.reference)

      //Valida si hay inventario en inventory
      $.getJSON("/products/check_inventory/" + id + ".json", (function(result_inventory) {
        $('.btn-add-to-cart').show()
        if (result_inventory.status == 200){
          $('.quantity-avaliable').text(result_inventory.dataRecords["unit_available"])
          $('#cart_item_quantity').attr({"max": result_inventory.dataRecords["unit_available"]})
          if (result_inventory.dataRecords["unit_available"] == 0){
            $('.btn-add-to-cart').hide()
          }
        }else{
          $('.btn-add-to-cart').hide()
          $('.quantity-avaliable').text(0)
        }        
      }));
    }));
  });
});

$(function() {
  $(document).on('click', '.remove-product', function() {
    parent_cart_item = $(this).parents('.cart-item')
    locale_conf = parent_cart_item.find('#locale_conf').val()
    item_id = parent_cart_item.find('#item_id').val()
    $.getJSON("/carts/recalculate_amount/" + locale_conf + '/' + item_id + ".json", (function(result) {
      if (result.response["success"]) {
       parent_cart_item.remove()
       $('.total').text(result.response["new_amount_currency"] + ' COP')
       $('.subtotal').text(result.response["subtotal_currency"] + ' COP')
       parent_cart_item.find('.new_item_amount').text(result.response["total_items_amount"] + ' COP')
       $('.new-amount').val(result.response["new_amount"])
       $('.new-signature').val(result.response["signature"])
      }
      if (result.response["success"] == false) {
        $('#message_error').removeClass('hidden')
      }
    }));
  });
});

$(function() {
  $(document).on('change', '#new-value', function() {
    new_value = $(this)
    console.log(new_value)
    parent_cart_item = $(this).parents('.cart-item')
    item_id = parent_cart_item.find('#item_id').val()
    locale_conf = parent_cart_item.find('#locale_conf').val()
    $.getJSON("/carts/recalculate_amount/" + locale_conf + '/' + item_id + '/' + new_value.val() + ".json", (function(result) {
      if (result.response["success"]) {
        if (new_value == undefined){
          parent_cart_item.remove()
        }
        $('.total').text(result.response["new_amount_currency"] + ' COP')
        $('.subtotal').text(result.response["subtotal_currency"] + ' COP')
        parent_cart_item.find('.new_item_amount').text(result.response["total_items_amount"] + ' COP')
        $('.new-amount').val(result.response["new_amount"])
        $('.new-signature').val(result.response["signature"])
      }
      
      if (result.response["success"] == false) {
        $('#message_error').removeClass('hidden')
      }
    }));
  });
});

$(function() {
  $(document).on('click', '.close', function() {
    $(this).parents('.alerts').addClass('animated fadeOutLeft').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
      $(this).removeClass('animated fadeOutLeft').remove();
    });
  });
});

$(function() {
  $(document).on('click', '.imgs-left', function() {
    imgs360('left')
  });
});

$(function() {
  $(document).on('click', '.imgs-right', function() {
    imgs360('right')
  });
});

$(function() {
  $(document).on('click', '.mini-imgs', function() {
    index = parseInt($(this).attr('index-image'))
    $('.img-active').addClass('hidden').removeClass('img-active')
    $('.mini-imgs').removeClass('mini-img-active')
    $('.zoom').eq(index).removeClass('hidden').addClass('img-active')
    $('.mini-imgs').eq(index).addClass('mini-img-active')
  });
});

function imgs360(action) {
  var indexActive = parseInt($('.img-active').attr('index-image'))
  var countImg = $('.zoom').length
  $('.img-active').addClass('hidden').removeClass('img-active')
  $('.mini-imgs').removeClass('mini-img-active')
  if (action == "right") {
    console.log('right');
    if ((indexActive + 1) >= countImg) {
      $('.zoom').eq(0).removeClass('hidden').addClass('img-active')
      $('.mini-imgs').eq(0).addClass('mini-img-active')
    }else{
      $('.zoom').eq(indexActive + 1).removeClass('hidden').addClass('img-active')
      $('.mini-imgs').eq(indexActive + 1).addClass('mini-img-active')
    }
  }else {
    console.log('left');
    if ((indexActive) <= 0) {
      $('.zoom').eq(-1).removeClass('hidden').addClass('img-active')
      $('.mini-imgs').eq(-1).addClass('mini-img-active')
    }else{
      $('.zoom').eq(indexActive - 1).removeClass('hidden').addClass('img-active')
      $('.mini-imgs').eq(indexActive - 1).addClass('mini-img-active')
    }
  }

  // $.each($('.zoom'), function(index, element) {
  //   var figureImg = $(this)
  //   if (parseInt(figureImg.attr('index-image')) == (indexActive + actionImg)) {
  //     figureImg.removeClass('hidden').addClass('img-active')
  //   }
  // });
}

$(function() {
  $(document).on('mousemove', '.zoom', function(e) {
      var img = $(this)
      var zoomer = e.currentTarget;
      var offsetX = e.offsetX
      var offsetY = e.offsetY
      x = offsetX/zoomer.offsetWidth*100
      y = offsetY/zoomer.offsetHeight*100
      img.css('background-position', x + '% ' + y + '%');
  });
});

$(function() {
  $(document).on('change keyup', '#shipping_country, #shipping_department, #shipping_city, #shipping_postal_code, #shipping_address', function() {
    var id = $(this).attr('id');
    var value = $(this).val();
    if (value.length == 0) {
      value = "null"
    }
    $.get("/carts/updated_address_cart/" + id + "/" + value, function() {});
    validAddress()
  });
});

function validAddress() {
  if (($('#shipping_country').val().length > 0) && ($('#shipping_department').val().length > 0) && ($('#shipping_city').val().length > 0) && ($('#shipping_address').val().length > 0)) {
    if (($('.btn-payment').hasClass('hidden'))) {
      $('.btn-no-payment').addClass('hidden')
      $('.btn-payment').removeClass('hidden').addClass('animated bounce').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        $(this).removeClass('animated bounce')
      });
    }
  }else{
    if (!($('.btn-payment').hasClass('hidden'))) {
      $('.btn-payment').addClass('hidden')
      $('.btn-no-payment').removeClass('hidden').addClass('animated bounce').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        $(this).removeClass('animated bounce')
      });
    }
  }
}

$(function() {
  $(document).on('change', '#shipping_country', function() {
    var value = $(this).val();
    $.getJSON("/carts/update_country_cart/"+ value, function(result) {
      console.log(result);
      var shippingCost = result.shipping
      var amountText = result.amount_text
      var amount = result.amount
      $('.shipping-cost-tag').text(shippingCost)
      $('.total').text(amountText)
      $('.new-amount').val(amount)
    });
  });
});

$(function() {
  $(document).on('click', '.btn-redeem-code', function() {
    var cart_id = $('#get_cart_id').val();
    var code = $('#code').val();
    $.getJSON("/carts/add_coupon_to_cart/"+cart_id+"/"+code, function(result) {
      if (result.success) {
        location.reload();
      }else{
        $('.error-coupon').removeClass('hidden')
        $('.error-coupon').text(result.message)
      }
    });
  });
});

$(function() {
  $(document).on('click', '.get-address-cart', function() {
    $.getJSON("/carts/get_address_cart.json", function(result) {
      $('#shipping_country').val(result.country)
      $('#shipping_department').val(result.department)
      $('#shipping_city').val(result.city)
      $('#shipping_postal_code').val(result.postal_code)
      $('#shipping_address').val(result.address)
      validAddress()
    });
  });
});

$(function() {
  $(document).on('click', '.open-redeem-code', function() {
    if ($('#redeemCode').hasClass('hidden')) {
      $('.open-redeem-code').removeClass('btn-success').addClass('btn-secondary')
      $('#redeemCode').removeClass('hidden')
    }else{
      $('.open-redeem-code').removeClass('btn-secondary').addClass('btn-success')
      $('#redeemCode').addClass('hidden')
    }
  });
});

$( document ).ready(function() {
  $('#isColombia').modal('show')
})